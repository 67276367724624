import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";
import {createSlice} from "@reduxjs/toolkit";
import {Application, Client} from "library";
import {GridLinkOperator} from "@mui/x-data-grid";

const getMatchingClients = createAppAsyncThunk("matchingClients/get", async (pageSize: number, {getState}) => {
    const {application: {item: application}, matchingClients: {forApplication, clients}} = getState();
    if (!application)
        return {forApplication: "", clients: []};

    if (forApplication === application.code)
        return {forApplication, clients};

    const result = await agent.Clients.list({
        pageSize,
        filter: {
            items: [
                {columnField: "primaryContact.displayName", value: application.applicant.displayName, operatorValue: "contains"},
                {columnField: "primaryContact.email.address", value: application.applicant.email.address, operatorValue: "contains"},
                {columnField: "primaryContact.socialInsuranceNumber", value: application.applicant.socialInsuranceNumber, operatorValue: "equals"}
            ],
            linkOperator: GridLinkOperator.Or,
        }
    });

    return {forApplication: application.code, clients: result.items};
});

const getMatchingApplications = createAppAsyncThunk("matchingApplications/get", async (_, {getState}) => {
    const {application: {item: application}, matchingClients: {forApplication, applications}} = getState();
    if (!application)
        return {forApplication: "", applications: []};

    if (forApplication === application.code)
        return {forApplication, applications};

    const result = await agent.Applications.list({
        filter: {
            items: [
                {columnField: "applicant.displayName", value: application.applicant.displayName, operatorValue: "contains"},
                {columnField: "applicant.email.address", value: application.applicant.email.address, operatorValue: "contains"},
                {columnField: "applicant.socialInsuranceNumber", value: application.applicant.socialInsuranceNumber, operatorValue: "equals"}
            ],
            linkOperator: GridLinkOperator.Or,
        }
    });

    return {forApplication: application.code, applications: result.applications.filter(a => a.code !== application.code)};
});

const initialState: { loadingClients?: boolean, loadingApplications?: boolean, forApplication?: string, clients?: Partial<Client>[], applications?: Partial<Application>[] } = {
    loadingClients: false,
    loadingApplications: false,
    clients: [],
    applications: []
};

const matchingClients = createSlice({
    name: "matchingClients",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getMatchingClients.pending, (state) => ({...state, loadingClients: true}));
        builder.addCase(getMatchingClients.fulfilled, (state, {payload}) => ({loadingClients: false, ...payload, applications: state.applications}));
        builder.addCase(getMatchingApplications.pending, (state) => ({...state, loadingApplications: true}));
        builder.addCase(getMatchingApplications.fulfilled, (state, {payload}) => ({loadingApplications: false, ...payload, clients: state.clients}));
    }
});

export default matchingClients.reducer;
export {getMatchingClients, getMatchingApplications};