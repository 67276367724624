import styles from "styles/applicationDuplicates.module.scss";

import { Card, Grid, Typography } from "@mui/material";
import { PpulusLoader } from "components";
import { DateDisplay, fullWidth, SinDisplay } from "library";
import { ApplicantInformation, Application } from "library/application";
import { useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { getMatchingApplications, getMatchingClients } from "store/slices";

type ApplicationDuplicatesProps = {
    application: Application | undefined;
}

const ApplicationDuplicates = ({ application }: ApplicationDuplicatesProps) => {
    if (!application?.applicant) return <></>;

    return (
        <>
            <ApplicantDetails applicant={application?.applicant} />
            <Grid container className={styles.duplicates}>
                <Typography variant={"h3"} >Potential Duplicate(s)</Typography>
                <ApplicationList />
                <ClientList />
            </Grid>
        </>
    );
};

type ApplicantDetailsProps = {
    applicant: ApplicantInformation;
}

const ApplicantDetails = ({ applicant }: ApplicantDetailsProps) => {
    const ValueDisplay = ({ label, value }: { label: string; value: string | undefined; }) => {
        return (
            <>
                <div>{label}</div>
                <div className={styles.value}>{value ?? "-"}</div>
            </>
        );
    };

    return (
        <Card className={styles.applicantDetails}>
            <div className={styles.values}>
                <ValueDisplay label={"First Name"} value={applicant.firstName} />
                <ValueDisplay label={"Last Name"} value={applicant.lastName} />
                <ValueDisplay label={"DOB"} value={DateDisplay.Long(applicant.dateOfBirth)} />
                <ValueDisplay label={"Email"} value={applicant.email.address} />
                <ValueDisplay label={"Phone #"} value={applicant.primaryPhone.number} />
                <ValueDisplay label={"SIN"} value={applicant.socialInsuranceNumber ? SinDisplay(applicant.socialInsuranceNumber) : undefined} />
            </div>
        </Card >
    );
};

const ApplicationList = () => {
    const dispatch = useDispatch();
    const { loadingApplications: loading, applications } = useSelector(state => state.matchingClients);

    useEffect(() => {
        dispatch(getMatchingApplications());
    }, [dispatch]);

    return (
        <Grid container columnSpacing={4}>
            <Grid item>
                <Typography variant={"h4"} className={styles.sectionTitle} >Applicant(s)</Typography>
            </Grid>
            <Grid item {...fullWidth}>
                <div className={styles.duplicateGrid}>
                    <PotentialDuplicateRow className={styles.header} id={"Application ID"} name={"Name"} dateOfBirth={"DOB"} email={"Email"} sin={"SIN"} submitted={"Submitted"} status={"Status"} entity={"Application"} />
                    {loading
                        ? <div className={styles.empty}><PpulusLoader /></div>
                        : applications?.length
                            ? applications.map((app, i) => <PotentialDuplicateRow key={i}
                                className={`${styles.row} ${i % 2 ? "" : styles.even}`}
                                id={app.code}
                                name={app.applicant?.displayName}
                                dateOfBirth={DateDisplay.Long(app.applicant?.dateOfBirth)}
                                email={app.applicant?.email.address}
                                sin={app.applicant?.socialInsuranceNumber}
                                submitted={DateDisplay.Standard(app.createdOn)}
                                status={app.status}
                                entity={"Application"} />)
                            : <div className={styles.empty}>The system could not find any potential application duplicates</div>}
                </div>
            </Grid>
        </Grid>
    );
};

const ClientList = () => {
    const dispatch = useDispatch();
    const { loadingClients: loading, clients } = useSelector(s => s.matchingClients);

    useEffect(() => {
        dispatch(getMatchingClients(0));
    }, [dispatch]);

    return (
        <Grid container columnSpacing={4}>
            <Grid item>
                <Typography variant={"h4"} className={styles.sectionTitle} >Recipient(s)</Typography>
            </Grid>
            <Grid item {...fullWidth}>
                <div className={styles.duplicateGrid}>
                    <PotentialDuplicateRow className={styles.header} id={"Client ID"} name={"Name"} dateOfBirth={"DOB"} email={"Email"} sin={"SIN"} programs={"Program(s)"} status={"Status"} entity={"Client"} />
                    {loading
                        ? <div className={styles.empty}><PpulusLoader /></div>
                        : clients?.length
                            ? clients.map((client, i) => <PotentialDuplicateRow key={i}
                                className={`${styles.row} ${i % 2 ? "" : styles.even}`}
                                id={client.code}
                                name={client.primaryContact?.displayName}
                                dateOfBirth={DateDisplay.Long(client.primaryContact?.dateOfBirth)}
                                email={client.primaryContact?.email.address}
                                sin={client.primaryContact?.socialInsuranceNumber}
                                programs={client.activeProgramNames?.join(", ")}
                                status={client.status}
                                entity={"Client"} />)
                            : <div className={styles.empty}>The system could not find any potential recipient duplicates</div>}
                </div>
            </Grid>
        </Grid>
    );
};

type EntityType = "Application" | "Client";

type PotentialDuplicateRowProps = {
    className?: string;
    id: string | undefined;
    name: string | undefined;
    dateOfBirth: string | undefined;
    email: string | undefined;
    sin: string | undefined;
    submitted?: string;
    programs?: string;
    status: string | undefined;
    entity: EntityType;
}

const PotentialDuplicateRow = ({ className, id, name, dateOfBirth, email, sin, submitted, programs, status, entity }: PotentialDuplicateRowProps) => {
    return (
        <>
            <div className={className}>{id ?? "-"}</div>
            <div className={className}>{name ?? "-"}</div>
            <div className={className}>{dateOfBirth ?? "-"}</div>
            <div className={className}>{email ?? "-"}</div>
            <div className={className}>{sin}</div>
            <div className={className}>{(entity === "Application" ? submitted : programs) ?? "-"}</div>
            <div className={className}>{status ?? "-"}</div>
        </>
    );
};

export {
    ApplicationDuplicates
};
